<template>
  <div>
    <Spinner v-if="isLoading" />
    <nav
         class="navbar navbar-expand-sm navbar-light bg-light border-bottom fixed-top">
      <router-link
                   to="/"
                   class="navbar-brand text-center">
        <img
             src="../assets/logo.jpg"
             width="auto"
             height="40"
             alt />
      </router-link>

      <!-- <sidebar-icon
        @click="toggle_menu = !toggle_menu"
        size="1.0x"
        role="button"
        class="text-secondary"
      ></sidebar-icon> -->

      <!-- <button @click="toggle_menu=!toggle_menu" class="btn btn-primary" id="menu-toggle">Toggle Menu</button> -->

      <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
           class="collapse navbar-collapse"
           id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item dropdown">
            <a
               class="nav-link dropdown-toggle"
               href="#"
               id="navbarDropdownMenuLink"
               data-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false">
              <div class="avatar mr-3">
                <div class="avatar__letters">{{ user_initials }}</div>
              </div>
            </a>
            <div
                 class="dropdown-menu dropdown-menu-right"
                 aria-labelledby="navbarDropdownMenuLink">
              <a
                 @click="logout"
                 class="dropdown-item"
                 href="#">
                <log-out-icon
                              size="1x"
                              class="custom-class"></log-out-icon>&nbsp;Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <div
         class="d-flex"
         :class="{ toggled: toggle_menu }"
         id="wrapper">
      <!-- Sidebar -->
      <div
           class="sidebar border-right"
           id="sidebar-wrapper">
        <div
             class="list-group list-group-flush sticky-top"
             style="overflow-y: auto; max-height: 90vh">
          <!-- ORDERS -->

          <div>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'Dashboard' }"
                         title="Dashboard">
              <grid-icon
                         size="1.0x"
                         class="custom-class"></grid-icon>
              <span class="d-none d-md-inline">&nbsp;Dashboard</span>
            </router-link>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'OrdersByProduct' }"
                         title="Orders by Product">
              <package-icon
                            size="1.0x"
                            class="custom-class"></package-icon>
              <span class="d-none d-md-inline">&nbsp;Orders by Product</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'OrdersByName' }"
                         title="Orders by Name">
              <users-icon
                          size="1.0x"
                          class="custom-class"></users-icon>
              <span class="d-none d-md-inline">&nbsp;Orders by Name</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'OrdersByPO' }"
                         title="Orders by PO">
              <file-text-icon
                              size="1.0x"
                              class="custom-class"></file-text-icon>
              <span class="d-none d-md-inline">&nbsp;Orders by PO</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'OrdersAll' }"
                         title="All Orders">
              <layers-icon
                           size="1.0x"
                           class="custom-class"></layers-icon>
              <span class="d-none d-md-inline">&nbsp;All Orders</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'LinesSentBack' }"
                         title="All Orders">
              <rewind-icon
                           size="1.0x"
                           class="custom-class"></rewind-icon>
              <span class="d-none d-md-inline">&nbsp;Sent Back</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'OrderForm' }"
                         title="Order Form">
              <file-plus-icon
                              size="1.0x"
                              class="custom-class"></file-plus-icon>
              <span class="d-none d-md-inline">&nbsp;Order Form</span>
            </router-link>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'OrderUpload' }"
                         title="Order Upload">
              <upload-icon
                           size="1.0x"
                           class="custom-class"></upload-icon>
              <span class="d-none d-md-inline">&nbsp;Order Upload</span>
            </router-link>
          </div>

          <!-- OUTWARDS -->

          <div v-if="can_access('outwards')">
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'OrderManagement' }"
                         title="Picking">
              <clipboard-icon
                              size="1.0x"
                              class="custom-class"></clipboard-icon>
              <span class="d-none d-md-inline">&nbsp;Order Management</span>
            </router-link>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'Picking' }"
                         title="Picking">
              <clipboard-icon
                              size="1.0x"
                              class="custom-class"></clipboard-icon>
              <span class="d-none d-md-inline">&nbsp;Picking</span>
            </router-link>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'Shipped' }"
                         title="Shipped">
              <truck-icon
                          size="1.0x"
                          class="custom-class"></truck-icon>
              <span class="d-none d-md-inline">&nbsp;Shipped</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'Invoiced' }"
                         title="Invoiced">
              <file-text-icon
                              size="1.0x"
                              class="custom-class"></file-text-icon>
              <span class="d-none d-md-inline">&nbsp;Invoiced</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'Tracking' }"
                         title="Tracking">
              <compass-icon
                            size="1.0x"
                            class="custom-class"></compass-icon>
              <span class="d-none d-md-inline">&nbsp;Tracking</span>
            </router-link>
          </div>

          <!-- INTWARDS -->

          <div v-if="can_access('inwards')">
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'PurchaseOrders' }"
                         title="Receiving">
              <clipboard-icon
                              size="1.0x"
                              class="custom-class"></clipboard-icon>
              <span class="d-none d-md-inline">&nbsp;Receiving</span>
            </router-link>

            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'Scan' }"
                         title="Scan">
              <radio-icon
                          size="1.0x"
                          class="custom-class"></radio-icon>
              <span class="d-none d-md-inline">&nbsp;Scan</span>
            </router-link>
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'Receipts' }"
                         title="Receive">
              <package-icon
                            size="1.0x"
                            class="custom-class"></package-icon>
              <span class="d-none d-md-inline">&nbsp;Receipts</span>
            </router-link>
          </div>

          <div v-if="can_access('edi')">
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light "
                         :to="{ name: 'edi' }"
                         title="EDI">
              <server-icon
                           size="1.0x"
                           class="custom-class"></server-icon>
              <span class="d-none d-md-inline">&nbsp;EDI</span>
            </router-link>


            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                         :to="{ name: 'ediordersummary' }"
                         title="EDI Order Summary">
              <list-icon
                         size="1.0x"
                         class="custom-class"></list-icon>
              <span class="d-none d-md-inline">&nbsp;EDI Summary</span>
            </router-link>



          </div>

          <router-link
                       class="list-group-item list-group-item-action bg-transparent text-light"
                       :to="{ name: 'Products' }"
                       title="Products">
            <package-icon
                          size="1.0x"
                          class="custom-class"></package-icon>
            <span class="d-none d-md-inline">&nbsp;Products</span>
          </router-link>

          <router-link
                       class="list-group-item list-group-item-action bg-transparent text-light"
                       :to="{ name: 'Customers' }"
                       title="Customers">
            <users-icon
                        size="1.0x"
                        class="custom-class"></users-icon>
            <span class="d-none d-md-inline">&nbsp;Customers</span>
          </router-link>
          <router-link
                       class="list-group-item list-group-item-action bg-transparent text-light"
                       :to="{ name: 'Groups' }"
                       title="Groups">
            <target-icon
                         size="1.0x"
                         class="custom-class"></target-icon>
            <span class="d-none d-md-inline">&nbsp;Groups</span>
          </router-link>
          <router-link
                       class="list-group-item list-group-item-action bg-transparent text-light"
                       :to="{ name: 'Rules' }"
                       title="Rules">
            <dollar-sign-icon
                              size="1.0x"
                              class="custom-class"></dollar-sign-icon>
            <span class="d-none d-md-inline">&nbsp;Rules</span>
          </router-link>

          <router-link
                       class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
                       :to="{ name: 'PriceList' }"
                       title="Price List">
            <list-icon
                       size="1.0x"
                       class="custom-class"></list-icon>
            <span class="d-none d-md-inline">&nbsp;Price List</span>
          </router-link>

          <!-- <div v-if="can_access('dropship')">
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'DropShip' }"
              title="DropShip">
              <shopping-cart-icon
                size="1.0x"
                class="custom-class"></shopping-cart-icon>
              <span class="d-none d-md-inline">&nbsp;DropShip</span>
            </router-link>
          </div> -->

          <!-- <router-link
            class="list-group-item list-group-item-action bg-transparent text-light"
            :to="{ name: 'StarshipitUpload' }"
            title="Starshipit Upload"
          >
            <upload-icon size="1.0x" class="custom-class"></upload-icon>
            <span class="d-none d-md-inline">&nbsp;Starshipit Upload</span>
          </router-link> -->
          <!-- <router-link
            class="list-group-item list-group-item-action bg-transparent text-light"
            :to="{ name: 'Admin' }"
            title="Admin"
          >
            <settings-icon size="1.0x" class="custom-class"></settings-icon>
            <span class="d-none d-md-inline">&nbsp;Admin</span>
          </router-link> -->
          <!-- <li class="nav-item">
            <hr class="line" />
          </li>-->

          <div v-if="isAdmin">
            <router-link
                         class="list-group-item list-group-item-action bg-transparent text-light"
                         :to="{ name: 'Users' }"
                         title="Users">
              <users-icon
                          size="1.0x"
                          class="custom-class"></users-icon>
              <span class="d-none d-md-inline">&nbsp;Users</span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <button
              class="btn btn-sm btn-dark sticky-top button-sidebar"
              @click="toggle_menu = !toggle_menu">
        <chevrons-right-icon
                             v-if="toggle_menu"
                             size="1.5x"
                             class="text-white"></chevrons-right-icon>
        <chevrons-left-icon
                            v-else
                            size="1.5x"
                            class="text-white"></chevrons-left-icon>
      </button>

      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="container-fluid py-3">
          <slot />
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>

<script>
// import navBar from "@/components/navBar";
import { auth } from "@/firebaseConfig.js";
import { actions, getters } from "@/store";
import Spinner from "@/components/Spinner";
// import { store, state, mutations, actions } from "@/store";
import {
  SidebarIcon,
  GridIcon,
  UsersIcon,
  // UserIcon,
  FilePlusIcon,
  ClipboardIcon,
  FileTextIcon,
  PackageIcon,
  TruckIcon,
  CompassIcon,
  LogOutIcon,
  // SettingsIcon,
  TargetIcon,
  UploadIcon,
  ServerIcon,
  DollarSignIcon,
  RadioIcon,
  ListIcon,
  LayersIcon,
  RewindIcon,
  ShoppingCartIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "vue-feather-icons";

// import footer from "@/components/Footer";

export default {
  name: "BasicLayout",
  components: {
    Spinner,
    SidebarIcon,
    GridIcon,
    UsersIcon,
    // UserIcon,
    FilePlusIcon,
    ClipboardIcon,
    FileTextIcon,
    PackageIcon,
    TruckIcon,
    CompassIcon,
    LogOutIcon,
    // SettingsIcon,
    TargetIcon,
    UploadIcon,
    ServerIcon,
    DollarSignIcon,
    RadioIcon,
    ListIcon,
    LayersIcon,
    RewindIcon,
    ShoppingCartIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
  },
  data() {
    return {
      toggle_menu: false,
    };
  },

  methods: {
    // ...mapActions(["clearUser"]),
    logout: function () {
      auth
        .signOut()
        .then(() => {
          actions.clearUser();
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    can_access(access_type) {
      const user_can_access = this.userProfile.access;
      return (
        user_can_access.includes("all") || user_can_access.includes(access_type)
      );
    },
  },
  computed: {
    // ...mapGetters(["userProfile"]),
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return getters.userProfile();
    },
    isLoading() {
      return getters.isLoading();
    },
    isAdmin() {
      return this.userProfile.role === "admin";
    },
    user_initials() {
      return this.userProfile.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
};
</script>

<style scoped>
.button-sidebar {
  display: block;
  margin-left: 0px;
  margin-right: 0;
  height: 64px;
  width: 36px;
  background-color: rgb(68, 61, 94);
}

#page-content-wrapper {
  margin-top: 80px;
}

.sticky-top {
  top: 80px;
}

#sidebar-wrapper {
  font-size: 1rem;
  background-color: #29343f;
  min-height: 100vh;
  margin-left: -5rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
}

#sidebar-wrapper .list-group {
  width: 5rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

.sidebar {
  background-color: rgb(103, 103, 243);
}

#wrapper #sidebar-wrapper {
  margin-left: 0;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
