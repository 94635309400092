import Vue from 'vue'
import Router from 'vue-router'
import { auth, db } from '@/firebaseConfig'

import Dashboard from './components/Dashboard.vue'
import Admin from './components/Admin.vue'

import OrdersByProduct from './components/OrdersByProduct.vue'
import OrdersByPO from './components/OrdersByPO.vue'
import OrdersByName from './components/OrdersByName.vue'

import OrderLinesByProduct from './components/OrderLinesByProduct.vue'
import OrderLinesByPO from './components/OrderLinesByPO.vue'
import OrderLinesByName from './components/OrderLinesByName.vue'

import PurchaseOrders from './components/PurchaseOrders.vue'
import PurchaseOrderLines from './components/PurchaseOrderLines.vue'

import OrdersAll from './components/OrdersAll.vue'
import LinesSentBack from './components/LinesSentBack.vue'
import Picking from './components/PickingByName.vue'
import PickingCustomerLines from './components/PickingCustomerLines.vue'

import Shipped from './components/Shipped.vue'
import Invoiced from './components/Invoiced.vue'
import ShippedLines from './components/ShippedLines.vue'
import InvoicedLines from './components/InvoicedLines.vue'

import OrderForm from './components/OrderFormAlgolia.vue'
import OrderEdit from './components/OrderEdit.vue'
import OrderUpload from './components/OrderUpload.vue'
import StarshipitUpload from './components/StarshipitUpload.vue'
import Customers from './components/Customers.vue'
import Products from './components/Products.vue'
import Rules from './components/Rules.vue'
import Groups from './components/Groups.vue'
import PriceList from './components/PriceList.vue'
import RuleEdit from './components/RuleEdit.vue'
import Tracking from './components/Tracking.vue'
import DropShip from './components/DropShip.vue'
import DropShipStores from './components/DropShipStores.vue'
import DropShipUpload from './components/DropShipUpload.vue'

import Receipts from './components/Receipts.vue'
import ReceiptCreate from './components/ReceiptCreate.vue'
import ReceiptEdit from './components/ReceiptEdit.vue'
import Scan from './components/Scan.vue'

import OrderManagement from './components/OrderManagement.vue'

import EDI from './components/EDIOrders.vue'
import EDIOrderSummary from './components/EDIOrderSummary.vue'
import Users from './components/users.vue'
import Login from './components/Login.vue'
import UserProfile from './components/userProfile.vue'
import UserCreate from './components/userCreate.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-by-po',
    name: 'OrdersByPO',
    component: OrdersByPO,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-by-product',
    name: 'OrdersByProduct',
    component: OrdersByProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-by-name',
    name: 'OrdersByName',
    component: OrdersByName,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-lines-by-product/:stock_code',
    name: 'OrderLinesByProduct',
    component: OrderLinesByProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-lines-by-po/:po_id',
    name: 'OrderLinesByPO',
    component: OrderLinesByPO,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-lines-by-name/:name_code',
    name: 'OrderLinesByName',
    component: OrderLinesByName,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/orders-all',
    name: 'OrdersAll',
    component: OrdersAll,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lines-sent-back',
    name: 'LinesSentBack',
    component: LinesSentBack,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/purchase-orders',
    name: 'PurchaseOrders',
    component: PurchaseOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/purchase-order-lines/:ourref',
    name: 'PurchaseOrderLines',
    component: PurchaseOrderLines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/picking',
    name: 'Picking',
    component: Picking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/picking-customer-lines/:name_code',
    name: 'PickingCustomerLines',
    component: PickingCustomerLines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shipped',
    name: 'Shipped',
    component: Shipped,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shipped-lines/:ssid',
    name: 'ShippedLines',
    component: ShippedLines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/invoiced',
    name: 'Invoiced',
    component: Invoiced,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/invoiced-lines/:seq',
    name: 'InvoicedLines',
    component: InvoicedLines,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-form',
    name: 'OrderForm',
    component: OrderForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-edit/:order_id',
    name: 'OrderEdit',
    component: OrderEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-upload',
    name: 'OrderUpload',
    component: OrderUpload,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/starshipit-upload',
    name: 'StarshipitUpload',
    component: StarshipitUpload,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricelist',
    name: 'PriceList',
    component: PriceList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rule/:rule_id',
    name: 'RuleEdit',
    component: RuleEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receipt-create',
    name: 'ReceiptCreate',
    component: ReceiptCreate,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/receipt-edit/:receipt_id',
    name: 'ReceiptEdit',
    component: ReceiptEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/scan',
    name: 'Scan',
    component: Scan,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/dropship',
    name: 'DropShip',
    component: DropShip,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/dropship-stores',
    name: 'DropShipStores',
    component: DropShipStores,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/dropship-upload',
    name: 'DropShipUpload',
    component: DropShipUpload,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/edi',
    name: 'edi',
    component: EDI,
    meta: {
      requiresAuth: true
      // requiresAdmin: true
    }
  },
  {
    path: '/edi-order-summary',
    name: 'ediordersummary',
    component: EDIOrderSummary,
    meta: {
      requiresAuth: true
      // requiresAdmin: true
    }
  },

  {
    path: '/order-management',
    name: 'OrderManagement',
    component: OrderManagement,
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user-create',
    name: 'UserCreate',
    component: UserCreate,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user/:id',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }

  ]
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const currentUser = auth.currentUser
  const userProfile = currentUser && await db.collection('users').doc(currentUser.uid).get()
  const isAdmin = userProfile && userProfile.data().role === 'admin'

  if (!requiresAuth) {
    next()
  } else if (requiresAuth && !currentUser) {
    next({
      path: '/login'
    })
  } else if (requiresAuth && currentUser) {
    if (!requiresAdmin) {
      next()
    } else if (requiresAdmin && isAdmin) {
      next()
    } else if (requiresAdmin && !isAdmin) {
      next(false)
    }
  }
})

export default router
