<template>
    <basicLayout>
        <div class="p-2">
            <h5>EDI ORDER SUMMARY</h5>
            <div class="row">
                <div class="col-6">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Search...</span>
                        </div>
                        <input
                               @input="debounce_search"
                               :value="search"
                               class="form-control"
                               placeholder="PO, SO, Name, or Carrier" />
                        <div class="input-group-append">
                            <button
                                    @click="clear_search"
                                    class="btn btn-outline-secondary"
                                    type="button">
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">From</span>
                        </div>
                        <input<input v-model="from_date" type="date" class="form-control" />
                    </div>
                </div>

            </div>


            <hr />
            <div class="row">
                <div class="tableFixHead tableFixHead">
                    <table class="table table-bordered table-hover table-sm">
                        <thead class="thead-dark">
                            <tr>
                                <th>Their Code</th>
                                <th>Description</th>
                                <th>Total Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(order, i) in filtered"
                                :key="order.their_stock_code">


                                <td class="text-left">
                                    {{ order.their_stock_code }}
                                </td>
                                <td
                                    class="text-left">

                                    {{ order.import_description }}
                                </td>
                                <td class="text-right">
                                    {{ order.quantity }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr />
        </div>
    </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";

const edi_orders = db.collection("edi");



// const start = new Date('2025-01-20')

export default {
    name: "edi_orders_summary",
    data() {
        return {
            display_lines: [],
            search: "",
            debounce: null,
            status_filter: "not_imported",
            sort_column: ["created", "desc"],
            sort_order: "asc",
            selected: [],
            selectAll: false,
            savedIndex: null,
            confirm_delete_ids: [],
            confirm_delete: false,
            delete_timeouts: [],
            from_date: new Date()

        };
    },
    components: {
        basicLayout,
    },
    mixins: [filter_mixins],
    // firestore: {
    //     edi_orders: db.collection("edi").where("created", ">", start).orderBy("created", "desc").limit(5),
    // },

    methods: {


        debounce_search(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.search = event.target.value;
            }, 600);
        },
        sort_by(column) {
            this.sort_column = column;
            this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
        },
        filter_by(filter) {
            this.selected = [];
            this.selectAll = false;
            this.search = filter;
        },
        clear_search() {
            this.selected_order = {};
            this.selectAll = false;
            this.search = "";
        },

    },
    computed: {
        filtered() {
            let visible_orders = this.display_lines.slice();

            console.table(visible_orders)
            const search = this.search;

            // if (!search) visible_orders = visible_orders.slice(0, 10);

            visible_orders = visible_orders.map(order => order.order_lines).flat()
            visible_orders = visible_orders.reduce((result, item) => {
                const existing = result.find(line => {
                    return line.their_stock_code === item.their_stock_code
                }
                );
                if (existing) {
                    existing.quantity += item.quantity
                } else {
                    result.push(item);
                }
                return result;
            }, [])



            visible_orders = visible_orders.filter((order) => {
                const s =
                    Object.values(order)

                const su = s.toString().toUpperCase();
                return su.match(search.toString().toUpperCase());
            });

            return visible_orders;
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        search: function (newSearch) {
            if (newSearch) {
                this.selected_order = {};
                this.selected_page = -1;
            } else {
                this.selected_page = 1;
            }
        },
        from_date: {
            immediate: true,
            handler(from_date) {

                let query = edi_orders.limit(10)

                if (from_date) {
                    query = query.where("created", ">=", new Date(from_date))
                }

                this.$bind("display_lines", query);
            },
        },
    },
};
</script>

<style scoped></style>
