<template>
  <basicLayout>
    <div class="row">
      <div class="col-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="my-0 font-weight-normal">Bulk Product Uploads</h5>
          </div>
          <div class="card-body">
            <div class="custom-file">
              <input
                     type="file"
                     class="custom-file-input"
                     id="customFile"
                     @change="file_picker" />
              <label
                     class="custom-file-label"
                     for="customFile">Choose file...</label>
            </div>
            <div class="row mt-2">
              <div
                   v-if="upload_progress > 0 && upload_progress < 99"
                   class="col mt-3">
                <div class="progress">
                  <div
                       class="progress-bar progress-bar-striped"
                       role="progressbar"
                       :style="{ width: upload_progress + '%' }"
                       :key="upload_progress"
                       aria-valuemin="0"
                       aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="my-0 font-weight-normal">Run Processes</h5>
          </div>

          <div class="card-body">
            <button
                    @click="ftp_checker()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Check FTP
            </button>

            <button
                    @click="force_moneyworks_run_sync_all()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Force MW Sync
            </button>

            <!-- <button
              @click="force_moneyworks_run_sync_all()"
              type="button"
              class="btn btn-outline-primary btn-block">
              MW Create SO
            </button> -->

            <button
                    @click="force_sync_run_sync_all()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Force Playlist Sync
            </button>

            <button
                    @click="force_update_all_products()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Force Update Playlist Products
            </button>

            <button
                    @click="get_blob('so')"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Download MW SO XML file
            </button>

            <button
                    @click="get_blob('rules')"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              Download MW Rules XML File
            </button>

            <button
                    @click="hn_fetch()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              HN FETCH
            </button>

            <button
                    @click="hn_cancel()"
                    type="button"
                    class="btn btn-outline-primary btn-block">
              HN CANCEL
            </button>
          </div>
        </div>
      </div>

      <div class="col-6">
        <Logs></Logs>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// import moment from "moment";
// import xml2js from "xml2js";
import { fb, db, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Logs from "@/components/Logs";

// import { doform } from "../mixins/utility_mixins";

export default {
  name: "admin",
  data() {
    return {
      lines: [],
      products: [],
      upload_progress: 0,
      mw_url: "evaluate/expr=today%28%29",
      mw_response: "",
      test_url: "",
      test_content: "",
      test_response: "",
      logs: [],
    };
  },
  components: { basicLayout, Logs },
  firestore: {
    // lines: db.collection("lines").where("is_outstanding", "==", true),
    // products: db.collection("products").where("is_outstanding", "==", true),
    // orders: db.collection("orders").where("is_outstanding", "==", false),
    logs: db.collection("logs"),
  },
  methods: {

    hn_fetch() {
      const hn_fetch_fn = fb.httpsCallable("harvey_norman_fetch_orders");
      hn_fetch_fn().then(function (result) {
        console.log(result);
      });
    },

    hn_cancel() {
      const hn_cancel_fn = fb.httpsCallable("harvey_norman_cancel_order");
      hn_cancel_fn().then(function (result) {
        console.log(result);
      });
    },




    ftp_checker() {
      const ftp_checker = fb.httpsCallable("ftp_checker");
      ftp_checker().then(function (result) {
        console.log(result);
      });
    },

    sync_products_allocate() {
      const sync_products_allocate = fb.httpsCallable("sync_products_allocate");
      sync_products_allocate().then(function (result) {
        console.log(result);
      });
    },

    string_to_number() {
      console.log("updating");
      this.orders
        .filter((order) => order.outstanding === false)
        .map((order) => {
          console.log(order.stock_code);
          db.collection("orders")
            .doc(order.id)
            // .update({ is_outstanding: false });
            .update({ is_outstanding: false });
        });
    },

    products_in_lines() {
      const products_in_lines = fb.httpsCallable("products_in_lines");
      products_in_lines().then(function (result) {
        console.log(result);
      });
    },

    run_sync_all() {
      const run_sync_all = fb.httpsCallable("run_sync_all");
      run_sync_all().then(function (result) {
        console.log(result);
      });
    },
    moneyworks_create_so() {
      console.log("CREATING SO");
      const moneyworks_create_so = fb.httpsCallable("moneyworks_create_so");
      moneyworks_create_so().then(function (result) {
        console.log(result);
      });
    },
    moneyworks_check_connection: async function () {
      this.mw_response = "";
      const moneyworks_check_connection = fb.httpsCallable("moneyworks_check_connection");
      const result = await moneyworks_check_connection(this.mw_url);
      console.log(JSON.stringify(result.data));
      this.mw_response = JSON.stringify(result.data);

      // const parser = new xml2js.Parser({
      //   ignoreAttrs: true,
      //   explicitArray: false,
      // });
      // const mw_table_parse = await parser.parseStringPromise(result.data);
      // this.mw_response = JSON.stringify(mw_table_parse);
    },

    // http_test() {
    //   this.test_response = "";
    //   var http_test = fb.httpsCallable("http_test");
    //   const request_options = {
    //     url: this.test_url,
    //     method: "POST",
    //     content: this.test_content,
    //   };

    //   http_test(request_options).then((result) => {
    //     console.log(result.data);
    //     this.test_response = result.data;
    //   });
    // },

    file_picker(event) {
      console.log("file picker");
      const file = event.target.files[0];
      console.log(file.type);
      this.upload(file);
      event.target.value = "";
    },

    upload: async function (file) {
      const tableURL = await this.uploadTaskPromise(file);
      console.log(`Successfully uploaded file and got download link - ${tableURL}`);
    },

    uploadTaskPromise: async function (file) {
      console.log("UPLOADING");
      return new Promise((resolve, reject) => {
        const storageRef = storage.ref(file.name);
        const uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.upload_progress = progress;
          },
          function error(err) {
            console.log("error", err);
            reject();
          },
          function complete() {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          }
        );
      });
    },
    ftp_fetch() {
      console.log("ftp_fetch");
      const ftp_fetch = fb.httpsCallable("ftp_fetch");
      ftp_fetch().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    get_po() {
      console.log("get_po");
      const moneyworks_get_po = fb.httpsCallable("moneyworks_get_po");
      moneyworks_get_po(457433).then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    force_moneyworks_run_sync_all() {
      console.log("force_moneyworks_run_sync_all");
      const force_moneyworks_run_sync_all = fb.httpsCallable("force_moneyworks_run_sync_all");
      force_moneyworks_run_sync_all().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    force_sync_run_sync_all() {
      console.log("force_sync_run_sync_all");
      const force_sync_run_sync_all = fb.httpsCallable("force_sync_run_sync_all");
      force_sync_run_sync_all().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    force_update_all_products() {
      console.log("force_update_all_products");
      const force_update_all_products = fb.httpsCallable("force_update_all_products");
      force_update_all_products().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    get_blob: async function (file_type) {
      console.log(file_type);

      let file_path;

      if (file_type === "so") {
        file_path = "moneyworks_so/import_so.xml";
      } else if (file_type === "rules") {
        file_path = "moneyworks_rules/import_names.xml";
      }

      const storageRef = storage.ref().child(file_path);

      let download_url = await storageRef.getDownloadURL();
      let res = await fetch(download_url);
      let blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "moneyworks_so.xml";
      anchor.click();
      this.target_file = "";
    },
  },

  computed: {
    filtered() {
      let visible_lines = this.lines.slice();
      visible_lines = visible_lines
        .map((line) => {
          const product = this.products.find((p) => p.code === line.stock_code);
          line.line_product = product;
          return line;
        })
        .filter((line) => {
          return !line.isNaN(line.soh);
        });

      // return line.shipped >= line.quantity && line.is_outstanding === true;

      return visible_lines;
    },
    po_ids() {
      return Array.from(new Set(this.lines.map((line) => line.order_id)));
    },
  },
};
</script>

<style scoped>
table {
  font-size: 0.6rem;
}

textarea {
  width: 100%;
  height: 350px;
  font-size: 0.8rem;
}
</style>
